.aboutContainer {
  width: 100%;
  padding: 2% 6% 0 6%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  h1 {
    margin: 3% 0;

    font-weight: bold;
    font-size: 4rem;
  }
  p {
    font-size: 1.5em;
  }
  h2 {
    margin-top: 9%;
    margin-bottom: 6%;
    font-weight: bold;
    font-size: 3rem;
  }
  .journeyContainer {
    margin-top: 8%;
    display: flex;
    justify-content: space-between;
  }
  .textContainer {
    width: 50%;
  }
  video {
    width: 48%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
  .videoContainer {
    margin-top: 6%;
    display: flex;
    justify-content: space-between;
  }
  .journeyImgContainer {
    display: flex;
    justify-content: space-between;
    width: 40%;
    flex-direction: column;
    align-items: center;
    margin: 6% 0;
    * {
      width: 110%;
      margin-bottom: 3%;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    }
  }
  h3 {
    font-weight: bold;
    margin: 2% 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .toolContainer {
    display: flex;
    height: 200px;
    flex-wrap: wrap;
    margin: 2% 0;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  a {
    text-decoration: none;
    color: black;
  }
  .fTool {
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 3px solid rgba(224, 51, 255, 0.201);
    margin: 10px 0;
    margin-right: 10px;
    font-weight: bold;
    background-color: rgba(238, 219, 219, 0.35);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
  .bTool {
    width: 200px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    border: 3px solid rgba(94, 108, 235, 0.241);
    margin: 10px 0;
    margin-right: 10px;
    font-weight: bold;
    background-color: rgba(238, 219, 219, 0.35);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
  .blockquote1 {
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    display: flex;
    height: 400px;
    margin-left: 25%;
    margin-top: 10%;
    flex-direction: column;
    width: 500px;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    position: relative;

    h1 {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 10%;
    }
  }
}
