@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,700');

$bg: #313942;
$ghost: #6d5295;
$heading: #6d5295;

.error-container {
  font-family: 'Montserrat', sans-serif;

  h1 {
    color: $heading;
    font-size: 12.5rem;
    letter-spacing: 0.1em;
    margin: 0.025em 0;
    text-shadow: 0.05em 0.05em 0 #6d529540;
    white-space: nowrap;
    font-weight: 700;

    @media (max-width: 30rem) {
      font-size: 8.5rem;
    }

    & > span {
      animation: spooky 2s alternate infinite linear;
      color: $ghost;
      display: inline-block;
    }
  }

  h2 {
    color: $heading;
    margin-bottom: 0.4em;
  }

  p {
    color: $heading;
    margin-top: 0;
  }

  .button {
    background-color: #6d5295;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 1.2rem;
    margin: 0 10px;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    
    &:hover {
      background: #fff;
      color: #6d5295;
    }
  }

  @keyframes spooky {
    from {
      transform: translateY(0.15em) scaleY(0.95);
    }

    to {
      transform: translateY(-0.15em);
    }
  }
}
