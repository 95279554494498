//Vars
:root {
  --rad: 0.7rem;
  --dur: 0.3s;
  --color-dark: #2f2f2f;
  --color-light: #fff;
  --color-brand: #57bd84;
  --font-fam: "Lato", sans-serif;
  --height: 3rem;
  --btn-width: 4rem;
  --bez: cubic-bezier(0, 0, 0.43, 1.49);
}

// Setup
.searchbar {
  font-size: 10px;

  // Main styles
  .formSearch {
    position: relative;
    width: 20rem;
    background: var(--color-brand);
    border-radius: var(--rad);
  }
  .inputSearch,
  .buttonSearch {
    height: var(--height);
    font-family: var(--font-fam);
    border: 0;
    color: var(--color-dark);
  }
  input[type="search"] {
    width: 100%;
    background: var(--color-light);
    padding: 0 1.6rem;
    border-radius: var(--rad);
    appearance: none;
    transition: all var(--dur) var(--bez);
    transition-property: width, border-radius;
    z-index: 1;
    position: relative;
    border: 1px solid gray;
    resize: none;
    outline: none;
  }
  .buttonSearch {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: var(--btn-width);
    font-weight: bold;
    background: var(--color-brand);
    border-radius: 0 var(--rad) var(--rad) 0;
  }
  input:not(:placeholder-shown) {
    border-radius: var(--rad) 0 0 var(--rad);
    width: calc(100% - var(--btn-width));
    + button {
      display: block;
    }
  }
  label {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
}
