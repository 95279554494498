.login-container {
  display: flex;
}

.login-register-forms {
  position: absolute;
}

.login-subcontainer {
  width: 90%;
  height: 90%;
  box-shadow: 0px 0px 40px 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 40px 6px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 40px 6px rgba(0, 0, 0, 0.5);
}

.login-form {
  display: grid;
  place-content: center;
  grid-template-columns: 1fr;
  justify-items: center;
  // opacity: 1;
  width: 50%;
  transition: all 0.9s ease;
}



.login-image {
  transition: all 0.8s ease-in;
  // border: !important;
  position: absolute;
  z-index: 100;
  width: 50%;
  height: 100%;
  //   filter: brightness(0) saturate(100%) invert(85%) sepia(14%) saturate(372%)
  //     hue-rotate(283deg) brightness(98%) contrast(86%);
}

.imageFilter {
  opacity: 0.7;
  // background-color: #88d7ed;
  //background-color: #e9c6d6;
  filter: brightness(0) saturate(100%) invert(85%) sepia(14%) saturate(372%) hue-rotate(283deg) brightness(98%) contrast(86%);
}

.hide-register-form {
  opacity: 0;
}

.hide-login-form {
  opacity: 0;
}

// fix the home link
.zIndex {
  z-index: 100;
}

.login-backto-home {
  background-color: black;
  padding: 10px 40px;
  transition: all 0.8s ease-in;
  // transition: left 0.8s ease-in;
}

.login-backto-home:hover {
  background-color: rgb(43, 40, 40);
}

.content {
  background-color: #cd9491;
  padding: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // height: 160px;
  height: fit-content;
  overflow: hidden;
  white-space: nowrap;
  font-family: 'Lato', sans-serif;
  font-size: 35px;
  line-height: 40px;
  color: #ecf0f1;

  &__container {
    font-weight: 600;
    overflow: hidden;
    height: 40px;
    padding: 0 40px;

    &:before {
      content: '[';
      left: 0;
      padding: 1rem;
    }

    &:after {
      content: ']';
      position: absolute;
      right: 0;
      padding: 1rem;
    }

    &:after,
    &:before {
      position: absolute;
      top: 0;

      color: #e3e2e2a0;
      font-size: 42px;
      line-height: 40px;

      -webkit-animation-name: opacity;
      -webkit-animation-duration: 2s;
      -webkit-animation-iteration-count: infinite;
      animation-name: opacity;
      animation-duration: 2s;
      animation-iteration-count: infinite;
    }

    &__text {
      display: inline;
      float: left;
      margin: 0;
    }

    &__list {
      margin-top: 0;
      padding-left: 110px;
      text-align: left;
      list-style: none;

      -webkit-animation-name: change;
      -webkit-animation-duration: 10s;
      -webkit-animation-iteration-count: infinite;
      animation-name: change;
      animation-duration: 10s;
      animation-iteration-count: infinite;

      &__item {
        line-height: 40px;
        margin: 0;
      }
    }
  }
}

@-webkit-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@-o-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@-moz-keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

@keyframes opacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@keyframes change {

  0%,
  12.66%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  16.66%,
  29.32% {
    transform: translate3d(0, -25%, 0);
  }

  33.32%,
  45.98% {
    transform: translate3d(0, -50%, 0);
  }

  49.98%,
  62.64% {
    transform: translate3d(0, -75%, 0);
  }

  66.64%,
  79.3% {
    transform: translate3d(0, -50%, 0);
  }

  83.3%,
  95.96% {
    transform: translate3d(0, -25%, 0);
  }
}

.login-register-image {
  width: 100%;
  height: 108%;
  object-fit: contain;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 1460px) and (min-width: 1225px) {
  .login-register-image {
    height: fit-content;
  }
}

@media (max-width: 1224px) {
  // .login-container {
  //   background: rgb(249, 186, 183);
  //   background: linear-gradient(180deg,
  //       rgba(246, 173, 169) 0%,
  //       rgb(249, 186, 183, 0.7763480392156863) 32%,
  //       rgb(255, 255, 255) 32%,
  //       rgb(255, 255, 255) 100%);
  // }

  .login-subcontainer {
    flex-direction: column;
  }

  .login-register-forms {
    position: initial;
  }

  .login-image {
    position: relative;
    width: 100%;
    height: 30% !important;
    inset: 0;

    & .login-backto-home {
      left: 0 !important;
      margin-top: 0 !important;
    }
  }

  .login-form {
    width: 100% !important;
  }

  .register-form {
    width: 100% !important;
    right: 0;
  }

  .content {
    font-size: 1.5rem;
    max-width: 70vw;
    max-height: 10vh !important;
    // margin: 0 auto;
    // text-align: center;
    padding: 1rem;
    z-index: 1;

    &__container {
      padding: 0 1rem;

      &:before {
        padding: 1rem;
      }

      &:after {
        padding: 1rem;
      }

      // &.content__container{
      //   padding: 4rem;
      // }

      &__list {
        margin-top: 0;
        padding-left: 4.5rem;
        text-align: left;
        list-style: none;
      }
    }
  }

}