.resize-left-handle {
  padding: 0 0.2rem;
  background-color: #e0e0e0;
  // width: 40px;
  // position: fixed !important;
  left: -10px !important;
  height: 100% !important;
  transition: all 0.2s ease-in-out;
  z-index: 450;
  cursor: ew-resize !important;

  &:hover,
  &:focus,
  &:active {
    background-color: #767676;
    padding: 0 0.4rem;
    width: 20px !important;
  }

  // .handle {
  //   display: flex;
  //   align-items: center;

  //   .handle__icon {
  //     margin-right: 1rem;
  //   }

  //   .handle__text {
  //     font-size: 1.2rem;
  //     font-weight: 500;
  //   }
  // }

  // .handle__icon {
  //   font-size: 1.5rem;
  //   color: #757575;
  // }
}