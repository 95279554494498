.todo {
  width: 35em;

  .addToDo {
    position: relative;
    width: 100%;
    height: 675px;
    border: 1px solid gray;
    margin-top: 2rem;
  }

  .inputbox {
    //height: 2.2rem;
    //outline: 0;
    //border-width: 0 0 2px;
    //border-color: rgb(81, 81, 94);
    padding-left: 0em;

    /*  &:focus {
      background-color: rgba(48, 134, 187, 0.14);
    } */
  }
  .addTaskButton {
    /* width: 10rem;
    height: 2.2rem; */
    background-color: rgba(48, 134, 187, 0.679);
    border: none;
  }

  .preparedTodo {
    flex-wrap: nowrap;
    box-shadow: 1px 1px 5px gray;
    &:hover {
      background-color: #add8e6;
      cursor: pointer;
    }
  }

  .tasksTitle,
  .completedTasksTitle {
    background-color: #55a6d8ad;
    width: 100%;
    font-weight: 300;
    margin: 0;
    padding: 0.4em;
    border-radius: 3px;
  }

  .todolabel {
    //background: #add8e6;
    height: 2.5rem;
    width: 100%;
    display: block;
    //border-bottom: 1px solid #2c3e50;
    color: #fff;
    //text-transform: capitalize;
    font-weight: 900;
    font-size: 11px;
    letter-spacing: 1px;
    text-indent: 10px;
    //cursor: pointer;
    transition: all 0.7s ease;
    position: relative;
    padding: 5px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    .deleteiIcon {
      color: rgb(47, 58, 49);
    }
    .deleteiIcon:hover {
      color: red !important;
      cursor: pointer;
    }
  }

  .todoDate {
    font-size: 0.8rem;
  }
  .checktodo {
    font-size: 1.5em;
    height: 2rem;
    background-color: red;

    & * {
      border-radius: 50%;
    }
  }

  .showTodo {
    visibility: visible;
  }
  .hideTodo {
    visibility: hidden;
  }
}
