.dashboard {
  // margin: 0 2%;
  // padding: 2% 0 ;

  .content-container {
    height: calc(100% - 48px);
  }

  .test-link {
    color: #262626;
    padding: 10px;
    border-radius: 8px;
    font-size: 1.2rem;
    // margin: 0  10px;
  }

  .test-link:hover {
    background: #262626;
    color: #fff;
  }
}
