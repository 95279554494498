.collabContainer {
  margin-top: 200px;

}

.teamH1 {
  text-align: center;
  height: 100px;
  font-size: 50px;
  margin-bottom: 80px;
}

.row {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.our-team {
  // width: 33.33%;
  min-height: 650px;
  border-radius: 15px;
  text-align: center;
  padding: 10px;
  background: rgba(255, 255, 255, 0.589);
}

.our-team .pic {
  background: #fff;
  padding: 10px;
  margin-bottom: 25px;
  display: inline-block;
  width: 15em;
  height: 15em;
  border-radius: 15px;
  transition: all 0.5s ease 0s;
}

.our-team:hover .pic {
  background: #3a3b3b;
  border-radius: 50%;
}

.pic img {
  width: 100%;
  // height: 100%;
  border-radius: 50%;
  // object-fit: cover;
  height: -webkit-fill-available;
  object-fit: cover;
  object-position: top;
}

.our-team .title {
  font-weight: 600;
  color: #2e282a;
  text-transform: uppercase;
  display: block;
  font-size: 20px;
  margin: 0 0 7px 0;
}

.our-team .post {
  color: #000000;
  text-transform: capitalize;
  display: block;
  font-size: 15px;
  margin-bottom: 15px;
  font-style: italic;
}

.our-team .social {
  padding: 0;
  margin: 0;
  list-style: none;
}

.our-team .social li {
  display: inline-block;
  margin-right: 5px;
}

.our-team .social li a {
  border-radius: 50%;
  font-size: 15px;
  color: #252525;
  border: 1px solid #252525;
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  transition: all 0.5s ease 0s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.our-team:hover .social li a {
  background: #252525;
  color: #fff;
}

/* .our-team .social li a > * {
  display: flex;
  justify-content: center;
  align-items: center;
} */
