.side-nav-container {
  // border-right: 2px solid hotpink;
  // background-color: white;
  min-width: 150px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.side-nav-body {
  justify-content: space-between;
  padding: 50px 0;
}

.side-nav-apps {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly; 
}

.side-nav-apps > div > :nth-child(1) {
  border-radius: 25px;
  padding: 10px;
}

.dashboard-apps {
  cursor: pointer;
}
