 .card-parent{
  cursor: initial;
 }
 
 .dragContainer{
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 30px;

.column{
width: 30%;
margin: 15px;

}
}

.droppableCol{
  width: 100%;
  background-color: gray;
  padding: 10px 10px 0 10px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;

}
.item {
  margin-bottom: 10px;
  background-color: gray;
  color: white;
  border: 1px solid white;
  padding: 5px;
  border-radius: 7px;
}

.item.dragging {
  background-color: coral;
}
.dragColumnContainer{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: rgb(246,237,188);
  background: linear-gradient(180deg, rgba(246,237,188,0.2721463585434174) 0%, rgba(236,133,132,0.23012955182072825) 20%, rgba(214,213,131,0.24413515406162467) 42%, rgba(171,185,228,0.2189250700280112) 67%, rgba(187,234,208,0.17690826330532217) 87%, rgba(245,254,255,0.1516981792717087) 100%);
}

.custom-card-header {
  // background-color: #ffffffc2 !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  color: rgb(42, 40, 40);
  text-align: center;
}

.addSpeedDial {
  box-shadow: 0px 0px 0px 7px #fff;
  border-radius: 3%;
}
