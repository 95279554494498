.loading-container {
  // height: 100vh;
  // background-color: #fff;
  // animation: animate 5s infinite both;
  // z-index: 9999;
  position: relative;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;

  .logo {
    animation: spin 2s linear infinite;
  }

  .loading-sub-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .loading {
    font-size: 2rem;
    font-weight: 700;
    color: #000;
    text-align: center;
    letter-spacing: 0.5rem;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
  }

  .loading span {
    // combine the animation together
    animation: blink 1.4s infinite both;
  }

  .loading span:nth-child(2) {
    animation-delay: 0.2s;
  }

  .loading span:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes spin {
    0% {
      transform: rotateY(0deg);
    }

    100% {
      transform: rotateY(360deg);
    }
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }

    20% {
      opacity: 1;
    }

    100% {
      opacity: 0.2;
    }
  }

  // animate the background color
  // @keyframes animate {
  //   0% {
  //     background-color: #fff;
  //   }

  //   20% {
  //     background-color: #F5F5DC;
  //   }

  //   40% {
  //     background-color: #FFFDD0;
  //   }

  //   60% {
  //     background-color: #FFDEAD;
  //   }

  //   80% {
  //     background-color: #F3E5AB;
  //   }

  //   100% {
  //     background-color: #fff;
  //   }
  // }
}
