.card-top-chips {
  top: -13px;
  left: 16px;
}

.card-right-chips {
  // top: 16px;
  right: 0;
}

.grid-item {
  @media (max-width: 600px) {
    padding: '0';

    &:nth-child(1) {
      margin-top: '25px';
      margin-bottom: '40px';
    }

    & :not(:nth-child(1)) {
      margin: '40px 0';
    }
  }
}