*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background: rgb(246, 237, 188);
  background: linear-gradient(
    39deg,
    rgba(246, 237, 188, 0.7763480392156863) 0%,
    rgba(236, 133, 132, 0.5158438375350141) 20%,
    rgba(214, 213, 131, 0.5354516806722689) 42%,
    rgba(171, 185, 228, 0.5298494397759104) 67%,
    rgba(187, 234, 208, 0.5438550420168067) 87%,
    rgba(245, 254, 255, 1) 100%
  );
}

/* General classNamees design */

#root,
.App {
  height: 100%;
  // overflow-x: hidden;
  // overflow-y: auto;
}

.rounded-links {
  border-radius: 5px;
}

.height-fit-content {
  height: fit-content;
}

#drawer {
  margin-left: 10px;
}

.jobs-container-main::-webkit-scrollbar {

  width: 25px;
}

.jobs-container-main::-webkit-scrollbar-track {

  background: #fff;
}

.jobs-container-main::-webkit-scrollbar-thumb {
  border-radius: 25px;
}


// reset bootstrap hr style
// hr {
//   height: inherit !important;
// }

// p,
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   white-space: pre-wrap;
// }

