
.jobCardContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black !important;


.notFavorite {
  font-size: 3.2rem !important;
  color: rgb(255, 205, 67) !important;
  position: absolute;
  top: -80px;
  left: 0;
  width: 50px;
  height: 50px;
}
.favorite {
  font-size: 3.2rem !important;
  color: rgb(255, 205, 67) !important;
  position: absolute;

  top: -80px;
  left: 0;
  width: 50px;
  height: 50px;
}
.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 80%;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 10px;
}
.colorPickerCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -75px;
  left: 70px;
  color: #fff; //old value was rgb(82, 82, 79)
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(112, 112, 112, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.192) 0px -2px 6px 0px inset;
}

.colorPickerContainer {
  position: absolute;
  top: -50px;
  left: 100px;
  z-index: 1000;
  background-color: rgba(245, 245, 245, 0.719);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(112, 112, 112, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.192) 0px -2px 6px 0px inset;
  border-radius: 15px;
  padding: 20px;
}
}
.mapCopy{
  visibility: hidden !important;
  z-index: -100000 !important;
  border: 1px solid red !important;
}

.google-autoComplete *:focus-visible{
  outline: none !important;
  border: none !important;
}