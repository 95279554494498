@import url("https://fonts.googleapis.com/css?family=Fira+Mono:400");
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@mixin flex-center($a, $b, $c, $d) {
  display: $a;
  flex-direction: $b;
  justify-content: $c;
  align-items: $d;
}

$myFirstColor: #d1dde7;
$mySecondcolor: #89625e;

$bg: #0aeb24;

/* 
===================================
        scrollbar style
==================================== */

  /* width */
  ::-webkit-scrollbar {
    width: 15px;
    background: rgb(255, 255, 255) ;
  }




/* Track */
::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px grey; 
    // border-radius: 15px;
    
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    // background: #4d4d4d; 
    // background: rgba(0, 0, 0, 0.865) ;
    background: #496964;

    // border-radius: 15px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    // background: #303030;
    background: #25322c;
}
/* 
======================
end of scrollbar style
======================= */

p {
  margin: 0 !important;
}

.nav-container{
  height: 9rem;
}