.board-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.card-container > div {
  padding: 30px 0;
}
.card-container > .col > div,
.custom-card-header {
  background-color: #ffffffc2 !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  //   background-color: #6d5295;
  color: rgb(42, 40, 40);
  text-align: center;
}
.jobCard-content > div > div {
  background-color: #6d5295;
}
.card-container-content > div {
  // padding: 15px;
}
.card-container-content > .row > div {
  font-weight: bolder;
  // padding: 15px;
  text-align: center;
}
