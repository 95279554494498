@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');

.containerNote {
  width: 500px;
  margin-right: auto;
  margin-left: auto;
  padding: 15px 0 40px 15px;


.notes-list {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  font-family: 'Reenie Beanie', cursive;
font-size: 30px;
font-weight: bold;
letter-spacing: 5px;
text-align: center;
text-transform: uppercase;
}

.notenew {
  background-color: #fef68a;
  height: 170px;
  width: 100%;

}

.textareaNotes {
  border: none;
  resize: none;
  background-color: #fef68a;
  height: 170px;
  width: 100%;
  padding: 10px;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Reenie Beanie', cursive;


}

.textareaNotes:focus {
  outline: none;
}

.save {
  background-color: #e1e1e1;
  border: none;
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
}

.save:hover {
  background-color: #ededed;
  cursor: pointer;
}

.note {
  border-radius: 10px;
  padding: 1rem;
  min-height: 170px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.note p {
  word-wrap: break-word;
  color: hsl(0, 0%, 100%);
}

.note-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.delete-icon {
  cursor: pointer;
}
}