@import url('https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap');

.landingPage-container {
  padding: 0 2%;
}

.main-header-container {
  height: 80vh;
  padding: 0 30px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.imageContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-parent {
  // box-shadow: rgb(100, 100, 100) -1px 14px 15px -2px;
  position: relative;
  z-index: 2;
  // background-color: #f2f3f2;
  // -webkit-box-shadow: 0 8px 6px -6px black;
  // -moz-box-shadow: 0 8px 6px -6px black;
  // box-shadow: 0 8px 6px -6px black;
}


.nav-link {
  color: black !important;
  border-radius: 5px;
  transition: all 0.4s cubic-bezier(1, 1, 0, 1) !important;
  margin: 0 10px;
}

.nav-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #fff;
  transition: width .3s;
}

.nav-link:hover::after,
.nav-link.active::after {
  width: 100%;
  transition: width .3s;
}

.nav-link:hover {
  background-color: black;
  color: white !important;
}

.new-feature {
  --border-width: 3px;
  --bgColor: linear-gradient(60deg,
      hsl(224, 85%, 66%),
      hsl(269, 85%, 66%),
      hsl(314, 85%, 66%),
      hsl(359, 85%, 66%),
      hsl(44, 85%, 66%),
      hsl(89, 85%, 66%),
      hsl(134, 85%, 66%),
      hsl(179, 85%, 66%));

  position: relative;
  border-radius: var(--border-width);
  color: #fff !important;
  font-weight: bold;


  // animation: moveGradient 4s alternate infinite;
  &::after {
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: var(--bgColor);
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: calc(2 * var(--border-width));
    animation: new_feature_anim 4s alternate infinite;
  }

  &:hover, &:focus, &:active {
    background-color: inherit !important;
    &::after {
      // animation: none;
      animation-play-state: paused;
    }
  }

  &.active {
    &::after {
      animation-play-state: paused;
    }
  }

  @keyframes new_feature_anim {
    50% {
      background-position: 100% 50%;
    }
  }
}


.nav-link.logout-btn {
  background-color: #f0614be3;
  color: white !important;
  border-radius: 5px;
  transition: all 0.01s ease-in !important;
}

.nav-link.logout-btn:hover {
  background-color: #cf4e3b;
  color: white !important;
}

.active:not(.new-feature) {
  background-color: black;
  color: white !important;
}


.header-cta-btn {
  border: none;
  background-color: #cf4e3b;
  padding: 20px 40px;
  width: fit-content;
  font-weight: 400;
  font-size: 42px;
  border-radius: 50px;
}

.header-cta-btn:hover {
  background-color: black !important;
  transition: all 0.4s cubic-bezier(0.5, 1, 0, 1) !important;
}

.typeContainer {
  width: 600px;
  height: 600px;
  padding-top: 5em;


}

.typeContainer h1 {

  font-family: 'Just Another Hand', cursive;
  font-size: 7em;
  letter-spacing: .15em;
  white-space: nowrap;
  overflow: hidden;
}

.typeContainer h1:nth-child(1) {
  width: 7.3em;
  -webkit-animation: type steps(40, end);
  animation: type 1s 2s steps(40, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-delay: 2s;
  opacity: 0;
}

.typeContainer h1:nth-child(2) {
  width: 8.2em;
  opacity: 0;
  -webkit-animation: type2 2s steps(40, end);
  animation: type2 2s steps(40, end);
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/* .secondText{
  font-family: 'Just Another Hand', cursive;
  letter-spacing: 5px;
  font-size: 50px;
  margin-top: 20px;
  color: darkcyan;
  display: block;
  animation:second_text_anim 1.5s 3.5s linear  alternate both;

} */
.tryNowBtn {
  animation: second_text_anim 1.5s 4s linear alternate both;
  background-color: rgba(251, 207, 76, 0.937);
  margin-top: -100px;
  width: 290px;
  font-size: 2rem;
  margin-left: 50%;

}

@keyframes type {
  0% {
    width: 0;
  }

  5% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }

  5% {
    opacity: 1;
  }

}

@keyframes type2 {
  0% {
    width: 0;
  }

  1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }

  1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    border: none;
  }
}

@keyframes type3 {
  0% {
    width: 0;
  }

  1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes type3 {
  0% {
    width: 0;
  }

  1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes second_text_anim {
  from {
    opacity: 0;
  }
}