.editProfile {
  /*   color variables */
  $clr-primary: #18ffff;
  $clr-primary-light: #adffff;
  $clr-primary-dark: #091034;
  $clr-gray100: #f9fbff;
  $clr-gray150: #f4f6fb;
  $clr-gray200: #eef1f6;
  $clr-gray300: #d0d0d0;
  $clr-gray400: #767b91;
  $clr-gray500: #4f546c;
  $clr-gray600: #2a324b;
  $clr-gray700: #161d34;
  $clr-bg: #060b23;
  /*   border radius */
  $radius: 0.5rem;

  .form {
    position: relative;
    width: 100%;
    height: 3rem;
    margin-bottom: 1.2rem;
    &__input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 2px solid $clr-gray300;
      border-radius: $radius;
      font-family: inherit;
      font-size: clamp(0.5rem, -0.2813rem + 2.5vw, 1.125rem);
      color: black;
      outline: none;
      padding: 1.25rem;
      background: none;
      &:hover {
        border-color: $clr-primary-light;
      }
      /* Change border when input focus*/
      &:focus {
        border-color: $clr-primary;
      }
    }
    &__label {
      position: absolute;
      top: -1.2rem;
      font-size: clamp(0.375rem, 0.0179rem + 1.1429vw, 0.875rem);
      left: 0.4rem;
      padding: 0 0.3rem;
      color: rgba(86, 84, 84, 0.781);
      cursor: text;
    }
  }

  .show {
    display: visible;
  }
  .hide {
    display: none;
  }

  .buttonRow {
    .closeButton {
      width: 95%;
      margin-right: 1em;
      background-color: rgba(255, 0, 0, 0.469);
    }
    .closeButton:hover {
      background-color: red;
    }

    .saveButton {
      width: 95%;
      margin-left: 1em;
      background-color: #93ddc186;
    }
    .saveButton:hover {
      background-color: #93ddc1;
    }
  }

  button {
    font-size: 1em;
    font-weight: bold;
    letter-spacing: 0.2em;
    border-radius: 5px;
  }
  .buttonRow,
  .buttonCol {
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 600px) {
  .editProfile {
    .saveButton,
    .closeButton {
      font-size: 0.7em;
    }
    
  }
}
