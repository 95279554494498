.personaltodos {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;


  .lefttodo {
    margin: 0;
    padding: 1em;
    height: 100%;
    border-right: 2px solid lightgray;

    .statusFilter {
      width: 100%;
      border-radius: 6px;
      height: 2em;
      color: #000;
      font-weight: bold;
      margin-bottom: 0.7em;
      display: flex;
      align-items: center;
      padding-left: 10px;
    }
    .statusFilter:hover {
      cursor: pointer;
      background-color: blueviolet;
      color: #fff;
    }
  }

  .righttodo {
    //background-color: aquamarine;
    height: 100%;
    padding: 30px 50px;

    .inputbox {
      &:focus {
        background-color: rgba(48, 134, 187, 0.14);
      }
      label {
        font-size: 1.2em;
      }
    }
    .addTaskButton {
      width: 12rem;
      background-color: rgba(48, 134, 187, 0.679);
      border-radius: 3px;
      height: 3rem;
      font-size: 1.2em;
      margin-bottom: -0.5em;

      &:hover {
        background-color: rgba(48, 134, 187, 0.743);
      }
    }

    .tasks {
      border-radius: 10px;
      margin-top: 1em;
    }
    .singleTodo {
      background-color: #5b9ec81f;

      &:hover {
        cursor: pointer;
        background-color: #5b9ec844;
      }
    }
    .checktodo {
      width: 5%;
    }

    .deleteIcon2 {
      width: 5%;
      color: rgba(0, 0, 0, 0.541);
    }

    .deleteIcon2:hover {
      cursor: pointer;
      color: red;
    }
    .todoText {
      width: 70%;
      font-size: 1.5em;
    }
    .completedTodoText {
      text-decoration: line-through;
      text-decoration-color: red;
    }
    .todoCompany {
      width: 10%;
      padding-left: 0.5em;
      font-size: 1.2em;

      &:hover {
        cursor: pointer;
        font-weight: bold;
      }
    }

    .todoDate {
      width: 10%;
    }
  }
}

@media screen and (max-width: 992px) {
  .personaltodos {
    padding-left: 5em;

    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;

    .lefttodo {
      //padding-left: 5em;
      height: 220px;
      width: 200px;
    }
    .todoText {
      width: 50%;

      font-size: small;
    }
    .todoDate {
      font-size: small;
      display: none;
    }
    .todoCompany {
      font-size: small;
    }
  }
}

@media screen and (max-width: 600px) {
  .personaltodos {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //align-items: center;

    .lefttodo {
    }

    .righttodo {
      width: 100%;
      margin-left: 1.6rem;

      .singleTodo {
        width: 100%;
        display: flex;
        justify-content: space-around;
      }
      .todoText {
        width: 35%;

        font-size: smaller;
      }
      .todoDate,
      .todoCompany {
        font-size: x-small;
      }

      .addTaskRow {
        display: flex;

        .addTaskButton {
          font-size: 0.8em;
          width: 80px;
        }
      }
    }
  }
}
