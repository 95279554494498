@import "../../general.scss";


.profile {
  overflow: hidden;

  .mainDiv {
    display: flex;
    justify-content: center;
    align-items: top;
    width: 100%;
    height: 100vh;
    font-family: "Roboto", Sans-Serif;
    margin-bottom: -10%;
    margin-top: 3%;

    .card {
      position: relative;
      width: 50%;
      height: 70%;
      border-radius: 10px;

      .profile-image {
        width: 150px;
        height: 150px;
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translate(-50%);
        border: 5px solid #4c4c4c;
        background: rgb(76, 76, 76);
        border-radius: 50%;
        z-index: 3;

        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: contain;
          }

        // ----------
        // profile-image
        // width: 150px;
        //   height: 150px;
        //   position: absolute;
        //   top: 10%;
        //   left: 50%;
        //   transform: translate(-50%);
        //   border: 5px solid #4c4c4c;
        //   border-radius: 50%;
        //   z-index: 3;
        //   background: rgb(76, 76, 76);


        // img
        // width: 180px;
        // position: absolute;
        // top: 10%;
        // left: 50%;
        // transform: translate(-50%);
        // z-index: 100;
        // border-radius: 50%;
        // ----------

      }

      .card-notblur {
        width: 100%;
        height: 350px;
      }

      .card-blur {
        width: 100%;
        height: 100vh;
        position: relative;

        .blur-background {
          position: absolute;
          background-color: rgba(0, 0, 0, 0.7);
          /* Black w/opacity/see-through */
          color: white;
          font-weight: bold;
          border: 1px solid #f1f1f1;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          width: 100%;
          padding: 20px;
          text-align: center;
          height: 100%;
        }
      }

      .card-texts {
        position: absolute;
        width: 100%;
        border-top: 1px solid rgb(226, 187, 187);
        display: flex;
        flex-direction: column;

        .title {
          color: $myFirstColor;
          width: 100%;
          height: 100px;
          padding-top: 2rem;
          text-align: center;
          margin-top: 1rem;
          z-index: 99;
        }

        // METRICS

        .metrics {
          height: 60px;
          z-index: 999;

          &:nth-child(1) {
            background-color: #f9315247;
          }

          &:nth-child(2) {
            background-color: #ffaa003e;
          }

          &:nth-child(3) {
            background-color: #00b74940;
          }

          &:nth-child(4) {
            background-color: #39c0ed4a;
          }

          &:nth-child(5) {
            background-color: #1268f143;
          }

          h5,
          h6 {
            text-align: center;
            color: #fff;
          }

          h5 {
            margin-top: 0.4rem;
            font-size: 12px;
            font-weight: bold;
          }
        }

        // USER INFOS

        .user-infos {
          margin: 1rem;
          padding-top: 1rem;
          z-index: 999;

          p {
            border-bottom: 1px solid rgb(255, 255, 255);
            color: rgb(255, 255, 255);
            padding-bottom: 0.2rem;
            display: flex;
            justify-content: space-between;

            span {
              color: #fff;

            }
          }
        }
      }

      .edit-div {
        position: absolute;
        width: 100%;
        bottom: -130px;
        height: 3rem;
        text-align: center;

        // EDIT PROFILE BUTTON
        /* From uiverse.io */
        button {
          position: relative;
          display: inline-block;
          cursor: pointer;
          outline: none;
          border: 0;
          vertical-align: middle;
          text-decoration: none;
          background: rgba(0, 128, 117, 0.386);
          padding: 0;
          font-size: inherit;
          font-family: inherit;
          border-radius: 22px;
          z-index: 999;

        }

        button.learn-more {
          width: 12rem;
          height: auto;
        }

        button.learn-more .circle {
          transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
          position: relative;
          display: block;
          margin: 0;
          width: 3rem;
          height: 3rem;
          background: rgba(255, 166, 0, 0.802);
          border-radius: 1.625rem;
        }

        button.learn-more .circle .icon {
          transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          background: #fff;
        }

        button.learn-more .circle .icon.arrow {
          transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
          left: 0.625rem;
          width: 1.125rem;
          height: 0.125rem;
          background: none;
        }

        button.learn-more .circle .icon.arrow::before {
          position: absolute;
          content: "";
          top: -0.29rem;
          right: 0.0625rem;
          width: 0.625rem;
          height: 0.625rem;
          border-top: 0.125rem solid #fff;
          border-right: 0.125rem solid #fff;
          transform: rotate(45deg);
        }

        button.learn-more .button-text {
          transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 0.75rem 0;
          margin: 0 0 0 1.85rem;
          color: #fff;
          font-weight: 700;
          line-height: 1.6;
          text-align: center;
          text-transform: uppercase;
        }

        button:hover .circle {
          width: 100%;
        }

        button:hover .circle .icon.arrow {
          background: #fff;
          transform: translate(1rem, 0);
        }

        button:hover .button-text {
          color: #fff;
        }
      }
    }
  }
}