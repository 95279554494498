.singleCardContainer {
  margin-bottom: 20px;
  // width: 100%;
  // display: flex;
  border-radius: 15px !important;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
  padding: 0.5rem;
  color: #ffffff !important;
  // font-size: 0.5em;
  -webkit-user-select: none;
  -moz-user-select: none;

  &:hover {
    cursor: pointer;
  }

  &:hover .deleteIcon,
  &:hover .expandIcon,
  &:hover .linkToJob {
    visibility: visible;
  }

  .linkArrow:hover {
    color: #f3acf1;
  }
  .deleteIcon {
    position: absolute;
    bottom: 5px;
    right: 10px;
    // font-size: 1.5rem !important;
    cursor: pointer;
    color: rgb(255, 255, 255);
    transition: transform 0.2s;
    // visibility: hidden;
    transition: 0.3s ease-in-out;
  }
  .deleteIcon:hover {
    transform: scale(1.1);
    color: #bde9f1;
  }
  .expandIcon {
    height: 1.6rem;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    transition: transform 0.2s;
    // visibility: hidden;
    transition: 0.3s ease-in-out;
  }
  .expandIcon:hover {
    transform: scale(1.1);
    color: #bde9f1;
  }

  .company {
    // font-size: 3.2rem;
    color: #ffffff;
    position: relative;
    // margin-top: -41px;
    // display: flex;
    // top: 0;
    // align-items: center;
  }
  .jobTitle {
    // margin-top: -22px;
    font-size: 1.2rem;
    display: inline;
    color: #ffffff;
    // margin-bottom: -0.5rem !important;
    font-style: italic;
  }
  .applicationDate {
    position: absolute;
    bottom: 0;
    left: 15px;
  }
  .iconButton {
    position: absolute;
    top: 0;
    right: 15px;
  }
  .linkToJob {
    font-size: 1.2rem;
    cursor: pointer;
    color: #ead1f5;
    position: absolute;
    top: 0;
    // visibility: hidden;
    // position: absolute;
    top: 0;
  }
  .linkToJob:hover {
    color: #bde9f1;
  }

  .disable-select {
    user-select: none;
  }

  .card-link {
    color: inherit;
  }

  .card-link:hover {
    color: #fff;
  }
}

.deleteOptionContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.deleteTooltip {
  position: absolute;
  //background-color: rgba(85, 83, 83, 0.727);
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 5px;
  bottom: 0px;
  right: 35px;
}
.deleteTooltipYes {
  transition: transform 0.2s;
  margin: 10px 5px;
}
.deleteTooltipNo {
  transition: transform 0.2s;
}
.deleteTooltipYes:hover,
.deleteTooltipNo:hover {
  transform: scale(1.1);
}
.starIcon {
  position: absolute;
  top: 10px;
  right: 50px;
}

@media only screen and (max-width: 2116px){
    .singleCardContainer .company{
        font-size: 2em !important;
    }
}

@media only screen and (max-width: 1632px){
    .singleCardContainer .company{
        font-size: 1.9em !important;
    }
}
@media only screen and (max-width: 1580px){
    .singleCardContainer .company{
        font-size: 1.8em !important;
    }
}
@media only screen and (max-width: 1529px){
    .singleCardContainer .company{
        font-size: 1.7em !important;
    }
}
@media only screen and (max-width: 1479px){
    .singleCardContainer .company{
        font-size: 1.6em !important;
    }
}
