$prime: #00ff80;
$second: #0c1016;

.container-header {
  background: rgb(38, 38, 38);
  color: white;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 20px;
  height: 70px;
}

.AllContactCards-parent > div {
  border: 1px solid #252525;
  margin-top: 20px;
  border-radius: 25px;
}

.AllContactCards-parent > div > div {
  padding: 20px;
}
